window.AppConfig = {
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  INTEGRATIONS_API_URL: "#{integrationsApiUrl}#",
  CLIENT_CREDENTIALS_API_URL: "#{clientCredentialsApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
};
